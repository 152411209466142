<template>
    <div class="error page-wrapper">
        <h1 class="text-white position-absolute vh-align">404 page</h1>
    </div>
</template>

<script>
export default {
    created() {
        document.dispatchEvent(new Event("custom-render-trigger"));

        setTimeout(() => {
            $("body").css("visibility", "unset");
            this.$store.dispatch("setLoading", false);
        }, loadingDelay);
    }
};
</script>
